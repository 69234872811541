import Analytics from '../components/Analytics';
import Intro from '../components/Intro';
import Layout from "../components/Layout";
import React from "react";
import SEO from "../components/Meta";
import { useIntl } from 'gatsby-plugin-intl';

function Upload() {
  const intl = useIntl();

  return (
    <Layout>
      <SEO
        title={intl.formatMessage({id: "title_upload", defaultMessage: "Laad hier je video op."})}
        description={intl.formatMessage({
          id: "description_upload",
          defaultMessage: "Ken je nog mensen die graag zingen? Maak jouw deelname bekend en nodig ze uit om ook mee te doen!"})}
        keywords={[`dww`, `de warmste week`, `het warmste koor`, `upload`]}
      />

      <Analytics
        title="Uploaden"
      />

      <Intro title={intl.formatMessage({id: "upload_title", defaultMessage: "Upload"})}>
        <div className="relative flex justify-center max-w-xl mx-auto mb-20">
          <iframe src="//interactief.radio2.be/20/3850FD3B-2EF2-44C9-A24A-851C7E3A8365/v1.cfm?id=3850FD3B-2EF2-44C9-A24A-851C7E3A8365" id="qualifio854790" className="relative z-10 mx-auto" width="100%" height="100%" scrolling="auto" frameBorder="0" hspace="0" vspace="0" style={{overflowX: 'hidden', maxWidth: '810px', minHeight: '1100px'}}>
          </iframe>

          <div className="absolute top-0 loader">Formulier aan het laden...</div>
        </div>
      </Intro>

    </Layout>
  );
}

export default Upload;
